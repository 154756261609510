import React from 'react'

const Footer = () => {
    return (
        <> <footer>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="mb-0 fs-small text-white text-footer"><span id="date"></span> Stepuplogics, All rights reserved</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <ul className="list-inline text-center usefulLinks mb-0 ms-auto">
                            <li className="list-inline-item">
                                <a href="https://www.Stepuplogics.com/privacy-policy" className="text-white">Privacy Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.Stepuplogics.com/refund-policy" className="text-white">Refund Policy</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.Stepuplogics.com/terms-condition" className="text-white">Terms & Condition</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer></>
    )
}

export default Footer;