import React from 'react'

const Welcome = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="title text-center">
                                <h2>Welcome to <span className="text-primary">Stepuplogics IT Services!</span></h2>
                            </div>
                            <p>
                                Stepuplogics is a global leader dedicated to delivering exceptional value through genuine customer delight. In a short span, we have earned a reputation for providing high-quality services tailored to meet the diverse needs of clients in India and beyond.
                            </p>
                            <p>
                                Our success stems from our meticulous approach to sourcing the right talent with the skills required to execute projects with excellence. Guided by a vision of continuity and sustainability, we are committed to continuous improvement in our services, ensuring that we consistently exceed the expectations of our valued customers.
                            </p>
                            To provide optimal solutions, we have established dedicated Projects and Systems Integration Units, ensuring seamless and efficient delivery for every client.
                            <p>
                                As a specialized software development company, Stepuplogics excels in web technologies, offering innovative and cost-effective software solutions. Our team comprises highly creative, dynamic, and passionate IT professionals with an unwavering focus on quality.
                            </p>
                            <p>
                                Our journey from a startup to a trusted service provider is a testament to our ability to evolve with industry demands while maintaining a relentless pursuit of excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Welcome