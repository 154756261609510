import React from 'react'
import meetcounsellor from '../../../assets/images/landing-img/web-development/meet-counsellor.svg';
import course from '../../../assets/images/landing-img/web-development/course-enrollment.svg';
import join from '../../../assets/images/landing-img/web-development/join-class.svg';
import prepare from '../../../assets/images/landing-img/web-development/meet-counsellor.svg';

const Feedback = () => {
    return (
        <>
            <section className='mbc-space'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="title text-center">
                                <h2>What Our <span className="text-primary">Clients Say!</span></h2>
                            </div>

                            <div className="jobcardsWrapper">
                                <div className="card jobcard">
                                    <img src={meetcounsellor} alt="" className="img-fluid" />
                                    <span className="num">01</span>
                                    <h6>Chat with Your Career Navigator</h6>
                                    <p>Discuss your career goals with our expert counsellor to get personalized guidance about how our course aligns with your professional objectives to help you get started in your IT career.
                                    </p>
                                </div>
                                <div className="card jobcard">
                                    <img src={course} alt="" className="img-fluid" />
                                    <span className="num">02</span>
                                    <h6>Dive into the Learning Zone</h6>
                                    <p>Sign up for our comprehensive full stack developer course tailored to industry needs to secure your spot in a program designed to transform beginners into skilled professionals.
                                    </p>
                                </div>
                                <div className="card jobcard">
                                    <img src={join} alt="" className="img-fluid" />
                                    <span className="num">03</span>
                                    <h6>Join The ClassName</h6>
                                    <p>Immerse yourself in interactive learning sessions led by industry experts. Engage with peers, get experience, and master IT concepts. Our dynamic classNamees prepare for real-world challenges.
                                    </p>
                                </div>
                                <div className="card jobcard">
                                    <img src={prepare} alt="" className="img-fluid" />
                                    <span className="num">04</span>
                                    <h6>Prepare for the Interview</h6>
                                    <p>Refine presentation skills and technical knowledge with our interview preparation sessions. Practice with mock interviews and gain the confidence to ace job interviews in the IT industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Feedback