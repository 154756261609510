import React, { useState } from 'react'
import QueryForm from '../../../components/query-form/QueryForm';

function FooterEnquery() {
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    return (
        <section className='bg-dark text-white position-sticky bottom-0 w-100 stickyEl'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex justify-content-center align-items-center">
                            <button onClick={openModal} className="btn btn-main rounded-0">
                                Enquire now
                            </button>
                            <a href="tel:+91 98-99-77-43-41" className="btn btn-transparent rounded-0 text-white"> <i className="bi bi-telephone"></i>+91-98-99-77-43-41</a>
                        </div>
                    </div>
                </div>
            </div>
            <QueryForm isOpen={isModalOpen} onClose={closeModal} />
        </section>
    )
}

export default FooterEnquery