import React from 'react'
import faq from '../../../assets/images/landing-img/faq.png';

const FrequentQuestions = () => {
    return (
        <section className="mbc-space">
            <div className="container">
                <div className="title text-center mbc-space">
                    <h2>Curious? Check Out Some of the Quirkiest Questions We Hear All the Time!</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <img src={faq} alt="" className="img-fluid w-75 d-block mx-auto pcEl" />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="formWrapper">
                            <div className="row">
                                <div className="col-lg-11 col-md-12 col-sm-12 ms-auto">
                                    <div className="formWrapper">
                                        <form method="POST" id="web-development">
                                            {/* <div className="offerImg">
                        <img src="https://www.Stepuplogics.com/assets/images/offer.png" alt="" className="img-fluid" />
                      </div> */}
                                            <h4 className="form-title">Let's talk about your career growth!</h4>
                                            <div className="input-group">
                                                <span className="input-group-text">+91</span>
                                                <input type="number" className="form-control mobileNumber" name="mobileNumber" placeholder="Mobile" aria-label="Mobile" min-length="10" max-length="10" />
                                            </div>
                                            <div className="input-wrapper"><label>Full Name<span className="red">*</span></label>
                                                <input type="text" name="lName" className="form-control lName" placeholder="Full Name" />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Email <span className="red">*</span></label>
                                                <input type="email" name="email" className="form-control email" placeholder="eg.yourname@gmail.com" />
                                            </div>
                                            <div className="input-wrapper">
                                                <label>Your Query<span className="red">*</span></label>
                                                <textarea name="message" className="form-control message" placeholder="Your Query"></textarea>
                                            </div>
                                            <div className="input-wrapper mb-0">
                                                <button type="submit" className="btn btn-main" id="myForm1">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FrequentQuestions