import webSites from '../../../assets/images/slides/webSites.png';
import communication from '../../../assets/images/slides/communication.png';
import costEffective from '../../../assets/images/slides/cost-effective.png';
import precision from '../../../assets/images/slides/precision.png';
import satisfaction from '../../../assets/images/slides/satishfaction.png';
import speed from '../../../assets/images/slides/speed.png';

const Carousel: React.FC = () => {
  const slides = [
    { src: webSites, alt: 'First slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
    { src: communication, alt: 'Second slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
    { src: costEffective, alt: 'Third slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
    { src: precision, alt: 'Fourth slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
    { src: satisfaction, alt: 'Fifth slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
    { src: speed, alt: 'Sixth slide', caption: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam.' },
  ];

  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      {/* Indicators */}
      <ol className="carousel-indicators">
        {slides.map((_, index) => (
          <li
            key={index}
            data-bs-target="#myCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
          ></li>
        ))}
      </ol>

      {/* Carousel Inner */}
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? 'active' : ''}`}
          >
            <img src={slide.src} alt={slide.alt} className="d-block w-100" />
            <div className="carousel-tagline">
              <p>{slide.caption}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Controls */}
      <a
        className="carousel-control-prev"
        href="#myCarousel"
        role="button"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#myCarousel"
        role="button"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </a>
    </div>
  );
};

export default Carousel;
