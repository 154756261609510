import logo from '../../../assets/images/landing-img/web-development/logo.png';

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top" id="navbar">
        <div className="container">
          <a className="navbar-brand text-white" href="">
            <img src={logo} alt="" className="img-fluid logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link text-white" href="">About Us</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownServices" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownServices">
                  <li><a className="dropdown-item" href="">Software Development</a></li>
                  <li><a className="dropdown-item" href="">Web Development</a></li>
                  <li><a className="dropdown-item" href="">Mobile App Development</a></li>
                  <li><a className="dropdown-item" href="">Educational Program</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMethodologies" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Methodologies
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMethodologies">
                  <li><a className="dropdown-item" href="">Delivery Models</a></li>
                  <li><a className="dropdown-item" href="">Process</a></li>
                  <li><a className="dropdown-item" href="">Strategies</a></li>
                  <li><a className="dropdown-item" href="">Engagement Model</a></li>
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link text-white" href="">Portfolio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
export default Navbar;
