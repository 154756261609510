import React from 'react';
import Navbar from './feature/landing-page/navbar/Navbar';
import Footer from './components/footer/Footer';
import Enquery from './feature/landing-page/footer-enquery/FooterEnquery';
import UniqueFeatures from './feature/landing-page/unique-features/UniqueFeatures';
import RoadmapToSuccess from './feature/landing-page/roadmap-to-success/RoadmapToSuccess';
import FrequentQuestions from './feature/landing-page/frequent-questions/FrequentQuestions';
import Carousel from './feature/landing-page/carousel/Carousel';
import Welcome from './feature/landing-page/welcome/Welcome';
import Feedback from './feature/landing-page/feedback/Feedback';

function App() {
  return (
    <>
      <Navbar />
      <Carousel />
      <Welcome />
      <UniqueFeatures />
      <Feedback/>
      <RoadmapToSuccess />
      <FrequentQuestions />
      <Footer />
      <Enquery />
    </>
  );
}

export default App;
