import React from 'react';
import './query-form.css'

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const QueryForm: React.FC<ModalProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="col-lg-9 col-md-10 col-sm-12 ms-auto">
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close-btn" onClick={onClose}>×</button>
                    </div>
                    <div className="modal-body">
                        <h4>Let's talk about your career growth!</h4>
                        <form method="POST">
                            <div className="input-group">
                                <span className="input-group-text">+91</span>
                                <input type="number" className="form-control mobileNumber" name="mobileNumber" placeholder="Mobile" aria-label="Mobile" minLength={10} maxLength={10} />
                            </div>
                            <div className="input-wrapper"><label>Full Name<span className="red">*</span></label>
                                <input type="text" name="lName" className="form-control lName" placeholder="Full Name" />
                            </div>
                            <div className="input-wrapper">
                                <label>Email <span className="red">*</span></label>
                                <input type="email" name="email" className="form-control email" placeholder="eg.yourname@gmail.com" />
                            </div>
                            <div className="input-wrapper">
                                <label>Your Query<span className="red">*</span></label>
                                <textarea name="message" className="form-control message" placeholder="Your Query"></textarea>
                            </div>
                            <div className="input-wrapper mb-0">
                                <button type="submit" className="btn btn-main">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueryForm;
